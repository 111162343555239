import { CssBaseline, Paper, BottomNavigation, BottomNavigationAction, Box } from "@mui/material";
import { useState, useCallback } from "react";
import { Icons } from "src/_mock/assets/icons/Icons";
import Iconify from "src/components/iconify";
import scrollToTop from "src/components/scroll-to-top";
import { updateWalletView } from "src/redux/slices/userSlice";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { IWalletView } from "src/sections/wallet/wallet-preview/types";
import navConfig from "./config-navigation";


export default function FixedBottomNavigation() {
    // const [value, setValue] = useState("home");

    const value = useAppSelector(state => state.user.walletView);
    const dispatch = useAppDispatch();


    const handleChangeView = useCallback(
        (newView: IWalletView) => {
            if (newView !== null) {
                dispatch(updateWalletView(newView))
                // setValue(newView)
            }
        },
        [dispatch]
    );
    return (
        <Box sx={{ pb: 7 }} >
            <CssBaseline />
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        handleChangeView(newValue);
                    }}
                >

                    {navConfig.map(item => (
                        <BottomNavigationAction value={item.value} label={item.title} icon={item.icon} />
                    ))}
                    {/* <BottomNavigationAction value='home' label="Home" icon={<Iconify icon={Icons.DefaultIcons.home} />} />
                    <BottomNavigationAction value='coupons' label="Coupons" icon={<Iconify icon={Icons.DefaultIcons.coupon} />} />
                    <BottomNavigationAction value='collections' label="Collections" icon={<Iconify icon={Icons.DefaultIcons.cards} />} />
                    <BottomNavigationAction value='rewards' label="Rewards" icon={<Iconify icon={Icons.DefaultIcons.reward} />} />

                    <BottomNavigationAction value='cards' label="Inventory" icon={<Iconify icon={Icons.DefaultIcons.inventory} />} /> */}
                </BottomNavigation>
            </Paper>
        </Box>
    );
}
