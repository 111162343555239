

export namespace DefaultIcons {
    export const subscriptionLock = "material-symbols:lock";
    export const insufficientCoins = "tabler:coin-off";
    export const filter = "ic:baseline-filter-alt";
    export const filterOff = "ic:baseline-filter-alt-off"
    export const gamification = 'ic:baseline-videogame-asset';
    export const airdrop = 'fluent-mdl2:parachute-solid';
    export const coin = 'ph:coin-fill';
    export const coins = 'ph:coins-fill';
    export const coupon = 'mdi:coupon';
    export const gift = 'gravity-ui:gift';
    export const giftcard = 'f7:giftcard-fill';
    export const add = 'mingcute:add-line';
    export const importIcon = 'mdi:import';
    export const collectCard = 'fluent:collections-24-filled';
    export const cards = "tabler:cards-filled";
    export const settings = "eva:settings-2-outline";
    export const reward = "fluent:reward-12-filled";
    export const productsStock = "fluent-mdl2:product-variant";
    export const loyalty = "material-symbols:loyalty-outline";
    export const home = "heroicons:home-solid";
    export const users = "mdi:users";
    export const campaign = "ic:round-campaign";
    export const quantity = "fluent-mdl2:quantity";
    export const text = 'ph:text-t-fill';
    export const date = 'solar:calendar-date-bold';
    export const qr = 'carbon:qr-code';
    export const info = 'ic:round-info';
    export const requirement = 'pajamas:requirements';
    export const balances = 'material-symbols:account-balance-rounded';
    export const summary = "material-symbols:wysiwyg-rounded";
    export const wallet = 'solar:wallet-bold-duotone';
    export const inventory = 'solar:wallet-bold-duotone';
    export const send = 'mingcute:send-plane-fill';
    export const inspect = 'material-symbols:frame-inspect-rounded';
}