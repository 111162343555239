/* eslint-disable no-plusplus */
/* eslint-disable import/no-cycle */
import {
    createEntityAdapter,
    createSelector,
    createSlice,
    EntityId,
    PayloadAction,
    Update,
} from '@reduxjs/toolkit'
import { giftoinApi, IGetManyResponse } from 'src/api/giftoinApi';
import { Giftoin } from 'src/types/Giftoin';
import { FirebaseTypes } from 'src/types/Firebase';
import { IGetInventoryResponse, inventoryApi } from 'src/api/inventoryApi';
import { SupportedNetworks } from 'src/types/Network';
import { ObjectValues } from 'src/types/utils';
import { Inventory } from 'src/types/Inventory';
import { RootState } from '../store';

// type IGiftoin = Giftoin.IGiftoin;

// const giftoinsAdapter = createEntityAdapter<IGiftoin>({
//     selectId: (giftoin) => giftoin.publicKey,
//     sortComparer: (a, b) => (a.giftedDate - b.giftedDate),
// });





interface IInitialState {
    cards: Readonly<Inventory.ICard>[];
    coins: Readonly<Inventory.ICoin>[];
    coupons: Readonly<Inventory.Coupon.ICoupon>[];
}
const initialState: IInitialState = {
    cards: [],
    coins: [],
    coupons: []
}



export const inventorySlice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {
        // Can pass adapter functions directly as case reducers.  Because we're passing this
        // as a value, `createSlice` will auto-generate the `bookAdded` action type / creator
        // bookAdded: giftoinsAdapter.addOne,
    },
    extraReducers(builder) {
        builder.addMatcher(
            inventoryApi.endpoints.getInventory.matchFulfilled,
            // Update the local giftoins cache
            (state, action: PayloadAction<IGetInventoryResponse>) => {
                // Check if its first time updating cache
                // const isConnectedFirstTime = state.cards.length === 0 && state.coins.length === 0 && state.coupons.length === 0;
                const { cards, coins, coupons } = action.payload;
                state = {
                    cards,
                    coins,
                    coupons,
                }
                return {
                    cards,
                    coins,
                    coupons
                }

            }
        )
    },

})

