/* eslint-disable import/no-cycle */


import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Giftoin } from 'src/types/Giftoin';
import { IInventoryAssetCoin } from 'src/types/Asset';
import { FirebaseTypes } from 'src/types/Firebase';
import { Inventory } from 'src/types/Inventory';
import { RootState } from '../store';


type IGiftoin = Giftoin.IGiftoin;


// Define a type for the slice state
interface ModalsState {
    notAvailableVersion: boolean;
    help: boolean;
    unwrap: boolean;
    general: IGeneralModalProps;
    giftoinInfo: IGiftoinInfoModalProps;
    giftoinReply: IGiftoinReplyModalProps;
    giftoinMessage: IGiftoinMessageModalProps;
    claim: boolean;
    rewards: boolean;
    send: ISend;
    showReward: boolean;
}

interface ISendBase {
    show: boolean;
}

interface ISendCoin extends ISendBase {
    type: 'coin';
    coin: Inventory.ICoin;
}
interface ISendCard extends ISendBase {
    type: 'card';
    card: Inventory.ICard;
}
interface ISendCoupon extends ISendBase {
    type: 'coupon';
    coupon: Inventory.ICoupon | FirebaseTypes.Giftoin.Item.ICoupon;
}


type ISend = { show: false } | ISendCoupon | ISendCoin | ISendCard;

interface IBaseModalProps {
    show: boolean;
}
interface IGiftoinMessageModalProps extends IBaseModalProps {
    giftoin: IGiftoin | undefined;
}

interface IGiftoinReplyModalProps extends IBaseModalProps {
    sendToEmail: string;
}

interface IGiftoinInfoModalProps extends IBaseModalProps {
    giftoin: IGiftoin | undefined;
}


interface IGeneralModalProps extends IBaseModalProps {
    text: string;

}

// Define the initial state using that type
const initialState: ModalsState = {
    notAvailableVersion: false,
    help: false,
    unwrap: false,
    send: {
        show: false,
    },
    claim: false,
    showReward: false,
    general: {
        show: false,
        text: "",
    },
    giftoinInfo: {
        giftoin: undefined,
        show: false,
    },
    giftoinReply: {
        show: false,
        sendToEmail: '',
    },
    giftoinMessage: {
        show: false,
        giftoin: undefined,
    },
    rewards: false,

}

export const modalsSlice = createSlice({
    name: "modals",
    initialState,
    reducers: {
        updateNotAvailableVersionModal: (state, action: PayloadAction<boolean>) => {
            state.notAvailableVersion = action.payload;

        },
        updateHelpModal: (state, action: PayloadAction<boolean>) => {
            state.help = action.payload;

        },
        updateUnwrapModal: (state, action: PayloadAction<boolean>) => {
            state.unwrap = action.payload;

        },
        updateGeneralModal: (state, action: PayloadAction<IGeneralModalProps>) => {
            state.general = action.payload;

        },
        updateGiftoinInfoModal: (state, action: PayloadAction<IGiftoinInfoModalProps>) => {
            state.giftoinInfo = action.payload;

        },
        updateGiftoinReplyModal: (state, action: PayloadAction<IGiftoinReplyModalProps>) => {
            state.giftoinReply = action.payload;

        },
        updateGiftoinMessageModal: (state, action: PayloadAction<IGiftoinMessageModalProps>) => {
            state.giftoinMessage = action.payload;
        },
        updateClaimModal: (state, action: PayloadAction<boolean>) => {
            state.claim = action.payload;
        },
        updateShowRewardModal: (state, action: PayloadAction<boolean>) => {
            state.showReward = action.payload;
        },

        updateShowSendModal: (state, action: PayloadAction<ISend>) => {
            state.send = action.payload;

        },

        updateRewards: (state, action: PayloadAction<boolean>) => {
            state.rewards = action.payload;
        },



    },

});

export const { updateClaimModal, updateShowSendModal, updateShowRewardModal, updateNotAvailableVersionModal, updateGiftoinReplyModal, updateGiftoinInfoModal, updateGiftoinMessageModal, updateHelpModal, updateUnwrapModal, updateGeneralModal } = modalsSlice.actions;


export const getNotAvailableVersionModal = createSelector((state: RootState) => state.modals.notAvailableVersion, (notAvailableVersion) => notAvailableVersion
)

export const getHelpModal = createSelector((state: RootState) => state.modals.help, (help) => help
)
export const selectUnwrapModal = createSelector((state: RootState) => state.modals.unwrap, (unwrap) => unwrap
)
export const selectGiftoinReplyModal = createSelector((state: RootState) => state.modals.giftoinReply, (giftoinReply) => giftoinReply
)

export const selectGeneralModal = createSelector((state: RootState) => state.modals.general, (general) => general
)
export const selectGiftoinInfoModal = createSelector((state: RootState) => state.modals.giftoinInfo, (giftoinInfo) => giftoinInfo
)
export const selectGiftoinMessageModal = createSelector((state: RootState) => state.modals.giftoinMessage, (giftoinMessage) => giftoinMessage
)







export default modalsSlice.reducer

